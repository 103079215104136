.FullCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  height: 100%;
  left: 1157px;
  background: #eeeeee;
  border-radius: 24px;
}

.Card1 {
  padding: 16px 24px 16px 16px;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  height: 164px;
  background: #ffe6e6;
  border: 4px solid #ffe6e6;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.Total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.SelectedReward {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 248.47px;
  height: 284.55px;
}
.ShowCase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11.1439px;
  width: 100%;
  height: 180.55px;
  background: #ffffff;
  border: 2.78596px solid #ffe6e6;
  box-shadow: 0px 2.78596px 11.1439px rgba(0, 0, 0, 0.12);
  border-radius: 11.1439px;
}

@media screen and (max-width: 1500px) {
  .Card1 {
    font-size: 14px;
    height: auto;
  }
  .ShowCase {
    font-size: 14px;
  }
}
