.cardSurvey {
  width: -webkit-fill-available;
  height: auto;
}

.imageBox {
  width: inherit;
  margin: 8px 8px 0 8px;
  padding: 6px 12px;
  border-radius: 8px;
  height: 120px;
  position: relative;
}
.surveyIcon {
  position: absolute;
  top: 50;
  left: 50;
}

.desc {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 28px;
  color: #000;
  white-space: nowrap;
}

.timerIcon {
  width: 16px;
  height: 16px;
}

.pointsIcon {
  min-width: 24px;
  min-height: 24px;
}

.quantity {
  font-family: "Baskerville";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #ff0000;
  text-align: end;
  width: -webkit-fill-available;
  padding: 0 4px;
}

.surveyBtn {
  text-transform: capitalize !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  margin: inherit !important;
  min-height: unset !important;
}

.survey-name-button-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  padding: 10px 10px;
}

.surveyName {
  width: 50%;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
