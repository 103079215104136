.userName{
    /* font-family: Proxima Nova; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px
}
.userMail{
    /* font-family: Proxima Nova; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px 
}