@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.image-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.text-overlay {
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  color: black;
}

.text-overlay h2 {
  font-family: "Poppins", sans-serif;
  font-size: 2em;
  margin-bottom: 10px;
}

.text-overlay p {
  font-size: 1.2em;
}

p {
  font-family: "Poppins", sans-serif;
}

.labelStyle {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 15px;
  color: #333333;
}
