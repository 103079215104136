.card {
  padding: 11.1439px;
  background: #ffffff;
  border: 2.78596px solid #ffe6e6;
  box-shadow: 0px 2.78596px 11.1439px rgba(0, 0, 0, 0.12);
  border-radius: 11.1439px;
  height: 185px;
  width: -webkit-fill-available;
}
.CompanyImg {
  width: 100%;
  height: 120px;
}

.VoucherDetail {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.CompanyLogo {
  position: relative;
  top: -15px;
  padding: 5px;
  width: 39px;
  height: 39px;
  background: #e2e2e2;
  border-radius: 5.57193px;
}

.Details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
}

.Details1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 4px;
}

.VoucherName {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  color: #545454;
}

.VoucherPoints {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #ffc043;
}

.surveyBtn {
  text-transform: capitalize !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  margin: inherit !important;
  min-height: unset !important;
  padding: 8px 12px !important;
}
