.cardStats{
    width: -webkit-fill-available;
    height: auto;
    border-radius: 8px;
}

.subCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* width: 171px;
    height: 92px; */
    padding: 8px;
}

.descriptionCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    /* width: 171px;
    height: 40px; */
}

.iconBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

.textBox {
    /* font-family: 'Proxima Nova'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* color: #FF3333; */
    white-space: nowrap;
}

.quantity {
    font-family: 'Baskerville';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    text-align: end;
    width: -webkit-fill-available;
    padding: 0 4px;
    margin-top: 16px;
}