.headerName {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 8px 16px 0px 0px;
  color: #ff5454;
  /* width: 188px;
  height: 48px; */
  font-size: 30px;
  /* border-radius: 4px 4px 0px 0px; */
}

.headerHL {
    
}
